import * as React from "react"
import './Warka.css';
import Creative from "../../components/Creative"
import backgroundWarka from "./images/warka_background.jpg"
import backImage from "./images/warka_backImage.png"
import glass from "./images/warka_glass.png"
import packshot from "./images/warka_packshot.png"
import video from "./images/warka_video.mp4"
import SimpleSidebar from "../../components/layout"


const Warka = () => (
  <SimpleSidebar>
    <Creative
      className="warka"
      video={video}
      background={backgroundWarka}
      backImage={backImage}
      heading="Warka BroBeer"
      tags={["animation"]}  
      tagBackground="#ffffff"
      tagColor="#b30b18"
      headingColor="#ffffff"
      variant="rightTop"
    >
      <img src={packshot} className="warka_image warka_packshot"/>
      <img src={glass} className="warka_image warka_glass"/>     
    </Creative>
  </SimpleSidebar>
)

export default Warka
